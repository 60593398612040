import { Box, Container } from "@mui/material";
import { createStyles } from "src/main/utils";

export default function PageDescription() {
  return (
    <Container
      sx={styles.root}
      maxWidth="sm"
    >
      <Box sx={styles.content}>
        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            Welcome sa WOWJILI, ang ultimate na game site para sa'yo! Dito sa WOWJILI, we offer a variety of exciting
            games na siguradong mag-eenjoy ka. From casual games to more challenging ones, we have it all. Kung
            naghahanap ka ng fun at engaging na laro, nandito lahat sa WOWJILI.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            Para makapag-start, gamitin lang ang WOWJILI LOGIN feature. Ang WOWJILI LOGIN process ay mabilis at madali,
            para makapaglaro ka agad-agad. Sa WOWJILI LOGIN, you can access your account, save your progress, at
            makipag-connect sa ibang players.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            Ang aming game selection sa WOWJILI ay palaging updated, para laging fresh ang gaming experience mo. With
            the WOWJILI LOGIN, pwede mong i-track ang achievements mo at makipaglaro with friends and other gamers. Kaya
            wag nang magpahuli, sign in with WOWJILI LOGIN today!
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            Sa WOWJILI, we prioritize your gaming experience. Kaya naman we ensure na ang aming platform ay
            user-friendly at secure. Ang WOWJILI LOGIN ay designed para maging safe ang information mo habang nag-eenjoy
            ka sa aming mga laro. Your satisfaction is our priority.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            Kung may questions ka o kailangan mo ng assistance, our customer support team is always ready to help.
            Whether may tanong ka sa WOWJILI LOGIN process or tungkol sa games namin, andito kami para i-assist ka. Sa
            WOWJILI, you are not just a player, you are part of a community.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            Kasama sa WOWJILI community, you will have access to exclusive content, promotions, at events na magpapasaya
            pa lalo sa iyong gaming experience. With the WOWJILI LOGIN, laging updated ka sa latest games at activities
            namin.
          </Box>
        </Box>

        <Box sx={styles.checkItem}>
          <Box sx={styles.checkDescription}>
            Join WOWJILI now at simulan na ang iyong gaming adventure! With the WOWJILI LOGIN, unlock a world of fun and
            excitement. Salamat sa pagpili ng WOWJILI as your gaming site. We look forward to providing you with the
            best gaming experience ever.
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

const styles = createStyles({
  root: {
    margin: "0px -20px",
    textAlign: "center",
    fontWeight: 700,
    pt: 3,

    img: {
      width: "100%",
    },
  },

  content: {
    padding: "0px 20px 20px",
  },

  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: "#F7A926",
    textShadow: "2px 2px 2px #4A2407, -2px 2px 2px #4A2407, -1px -1px 0 #4A2407, 1px -1px 0 #4A2407;",

    img: {
      height: "38px",
      width: "auto",
    },
  },

  description: {
    lineHeight: "1.15",
    color: "#F7A926",
    fontSize: "30px",
    WebkitTextStroke: "1px #4A2407",
    textShadow: "2px 2px 2px #4A2407, -2px 2px 2px #4A2407, -1px -1px 0 #4A2407, 1px -1px 0 #4A2407;",
  },

  checkItem: {
    color: "#fff",
    textAlign: "left",
    margin: "24px 0",
  },

  checkTitle: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    fontSize: "18px",
    marginBottom: "8px",

    img: {
      width: "24px",
      marginRight: "8px",
    },
  },

  checkDescription: {
    fontSize: "14px",
    fontWeight: 400,
  },
});
