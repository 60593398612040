import { getRedirectUrl } from "@99tech/shared-utils";
import { Box, Container, ContainerProps } from "@mui/material";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Bg } from "src/assets";
import { ENTER_SITE_URL, RefCodeMap } from "src/main/constants";
import { useFbcid } from "src/main/hooks";
import { createStyles, isMobile } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;
  const { fbcid } = useFbcid();
  const [params] = useSearchParams();

  const handleClickPage = useCallback(() => {
    const url = getRedirectUrl(ENTER_SITE_URL, fbcid, params, RefCodeMap);
    window.open(url, "_blank", isMobile() ? "width=full,height=full" : "");
  }, [fbcid, params]);

  return (
    <Box
      sx={styles.root}
      onClick={handleClickPage}
    >
      <Container
        {...containerProps}
        sx={styles.container}
        maxWidth="sm"
      >
        {children}
      </Container>
    </Box>
  );
};

const styles = createStyles({
  root: {
    animation: `bgmove 500s linear infinite`,
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: "repeat-y",
    backgroundColor: "black",
    minHeight: "100vh",
    cursor: "pointer",
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
});

export default Content;
