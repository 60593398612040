import { VisuallyHidden } from "@99tech/ui";
import { Box } from "@mui/material";
import React from "react";
import {
  Cards,
  Cash,
  Characters,
  EnterSite,
  Logo,
  Scatter,
  Slotmachine,
  SlotmachineGlow,
  Trophy,
  Wild,
  WildSuperac,
} from "src/assets";
import { PageDescription } from "src/main/components";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">Wowjili</VisuallyHidden>
      <Box sx={{ position: "relative", width: 360, textAlign: "center" }}>
        <img
          alt="trophy"
          src={Trophy}
          style={{
            animation: "trophy 1.6s infinite",
            position: "absolute",
            width: 92,
            top: -48,
            left: -2,
          }}
        />
        <img
          alt="logo"
          src={Logo}
          style={{ width: 200 }}
        />
        <img
          alt="wild"
          src={Wild}
          style={{
            animation: "wild 1.6s infinite",
            position: "absolute",
            width: 92,
            top: -40,
            right: -4,
          }}
        />
      </Box>

      <img
        alt="enter site"
        src={EnterSite}
      />

      <Box sx={styles.images}>
        <img
          alt="wild superac"
          src={WildSuperac}
          style={{
            animation: "wildsuperac 1.6s infinite",
            width: 100,
            bottom: 360,
            left: 10,
          }}
        />
        <img
          alt="scatter"
          src={Scatter}
          style={{
            animation: "scatter 1.6s infinite",
            width: 100,
            bottom: 360,
            right: -10,
          }}
        />

        <img
          alt="characters"
          src={Characters}
          style={{
            animation: "characters 1.6s infinite",
            width: 360,
            bottom: 200,
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />

        <img
          alt="cards"
          src={Cards}
          style={{
            width: 104,
            bottom: 54,
            right: -4,
          }}
        />

        <img
          alt="characters"
          src={Slotmachine}
          style={{
            width: 330,
            bottom: 54,
            right: 4,
          }}
        />

        <img
          alt="characters"
          src={SlotmachineGlow}
          style={{
            animation: "slotmachine 2.5s infinite",
            width: 330,
            bottom: 54,
            right: 4,
          }}
        />

        <img
          alt="cash"
          src={Cash}
          style={{
            width: 155,
            bottom: 50,
            left: -5,
          }}
        />
      </Box>

      <PageDescription />

      <Box sx={styles.footer}></Box>
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "10vh",
    pb: "40px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "500px",
    img: {
      position: "absolute",
    },
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
    background: "radial-gradient(800px 100% at 50% 100%, #FFF9C1 0%, #21966C 19%, rgba(0, 0, 0, 0.00) 50%)",
  },
});

export default LandingPage;
