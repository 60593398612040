import { Box, BoxProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  GetAppButton,
  GetAppTitle,
  HelpGifOpenExtBrowser,
  HelpGifOpenExtBrowserMp4,
  HelpGifOpenShareAddHomescreen,
  HelpGifOpenShareAddHomescreenMp4,
} from "src/assets";
import { useAppContext } from "src/main/constants";
import { useGtmTracker } from "src/main/hooks";
import { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles } from "src/main/utils";
import HelpGifDialog from "../HelpGifDialog";

interface BeforeInstallEvent {
  prompt: () => void;
  userChoice: Promise<any> | null;
}

interface InstallBannerProps extends BoxProps {}

const InstallBanner: React.FC<InstallBannerProps> = (props) => {
  const { ...rest } = props;
  const { sendEvent, sendSingularEvent } = useGtmTracker();
  const { isFbIab = false, isLineIab = false, isIosDevice = false } = useAppContext();
  const [requestedInstall, setRequestedInstall] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallEvent | null | undefined>();

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as unknown as BeforeInstallEvent);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, [setDeferredPrompt]);

  useEffect(() => {
    if (requestedInstall && (isFbIab || isLineIab)) {
      sendSingularEvent(EvtAction.ShowOpenExtBrowser);
    }
  }, [sendSingularEvent, requestedInstall, isFbIab, isLineIab]);

  useEffect(() => {
    if (requestedInstall && !isFbIab && !isLineIab && isIosDevice) {
      sendSingularEvent(EvtAction.ShowIosAddToHome);
    }
  }, [sendSingularEvent, requestedInstall, isFbIab, isLineIab, isIosDevice]);

  // const canInstall = deferredPrompt !== null && deferredPrompt !== undefined;

  // if (!canInstall && !isFbIab && !isLineIab && !isIosDevice) return null;
  // if (isFullScreen) return null;

  const install = () => {
    (async () => {
      deferredPrompt?.prompt();
      try {
        const choiceResult = await deferredPrompt?.userChoice;
        setDeferredPrompt(null);
        if (choiceResult?.outcome === "accepted") {
          // success
          sendEvent(EvtAction.InstallSuccess);
        } else if (choiceResult?.outcome === "dismissed") {
          // dismissed
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    sendEvent(EvtAction.ClickInstallBanner);
    if (isFbIab || isLineIab || isIosDevice) {
      setRequestedInstall(true);
    } else {
      install();
    }
  };

  return (
    <Box
      sx={styles.root}
      {...rest}
    >
      <Box
        sx={styles.container}
        onClick={handleClick}
      >
        <Box sx={styles.action}>
          <img
            src={GetAppTitle}
            style={{ height: "60px" }}
            alt="get app"
          />
          <img
            src={GetAppButton}
            alt="get app button"
            style={{ height: "60px" }}
          />
        </Box>
      </Box>
      <HelpGifDialog
        title="ดาวน์โหลด Wowjili.com!"
        src={HelpGifOpenExtBrowser}
        fallbackSrc={HelpGifOpenExtBrowserMp4}
        open={requestedInstall && isLineIab}
        onClose={() => setRequestedInstall(false)}
      />
      <HelpGifDialog
        title="ดาวน์โหลด Wowjili.com!"
        src={HelpGifOpenExtBrowser}
        fallbackSrc={HelpGifOpenExtBrowserMp4}
        open={requestedInstall && isFbIab}
        onClose={() => setRequestedInstall(false)}
      />
      <HelpGifDialog
        title="ดาวน์โหลด Wowjili.com!"
        src={HelpGifOpenShareAddHomescreen}
        fallbackSrc={HelpGifOpenShareAddHomescreenMp4}
        open={requestedInstall && !isFbIab && !isLineIab && isIosDevice}
        onClose={() => setRequestedInstall(false)}
      />
    </Box>
  );
};

const styles = createStyles({
  root: {
    marginX: "-16px",
    "@media (min-width: 600px)": {
      marginX: "-24px",
    },
  },
  container: {
    height: 71,
    background:
      "linear-gradient( 90deg, rgb(132,131,131) 0%, rgb(191,191,191) 17%, rgb(250,250,250) 50%, rgb(188,188,188) 100%)",
    paddingLeft: "12px",
    paddingRight: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1700,
    maxWidth: 600,
    "@media (min-width: 600px)": {
      // marginX: "-24px",
      marginX: "calc(50vw - 300px)",
    },
  },
  action: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 600,
    alignItems: "center",
  },
});

export default InstallBanner;
